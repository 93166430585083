<template>
  <div class="home">
    <div class="nav">
      <div class="nav-list">
        <div :class="{ active: type == 0 }" @click="getUserList(0)">
          {{ currentLang.online }}
        </div>
        <div :class="{ active: type == 1 }" @click="getUserList(1)">
          {{ currentLang.lastest }}
        </div>
        <div :class="{ active: type == 2 }" @click="getUserList(2)">
          {{ currentLang.myFavor }}
        </div>
        <div :class="{ active: type == 3 }" @click="getUserList(3)">
          {{ currentLang.likeMe }}
        </div>
        <div :class="{ active: type == 4 }" @click="getUserList(4)">
          {{ currentLang.mutual }}
        </div>
      </div>
      <div class="search">
        <!-- 搜索 -->
        <img style="width: 30px; height: 30px" :src="require('../../assets/img/search.png')" @click="toSearch()" />
      </div>
    </div>
    <div class="person">
      <div class="item" v-for="item in memberList" :key="item.id">
        <div class="headimg" @click="viewDetails(item)">
          <div class="myPhoto">
            <img style="width: 150px; height: 150px" :src="item.avatar" />
            <p style="height: 20px">
              <!-- 这里加上判断，如果是金卡显示皇冠，如果是银卡显示。。 -->
              <img v-if="item.fklx == '7978'" style="width: 30px; height: 30px; top: 120px"
                :src="require('../../assets/img/zuanshi.png')" />
              <img v-if="item.fklx == '7979'" style="width: 30px; height: 30px; top: 120px"
                :src="require('../../assets/img/huangguan.png')" />
            </p>
          </div>
        </div>
        <div class="user">
          <div class="name">
            <span style="padding-right: 6px">{{ item.name == '' ? '匿名用户' : item.name }}</span>
            <span>
              <img style="width: 14px; height: 14px; vertical-align: middle" v-if="item.sex == '女'"
                :src="require('../../assets/img/sex0.png')" />
              <img style="width: 14px; height: 14px; vertical-align: middle" v-else
                :src="require('../../assets/img/sex1.png')" />
            </span>
          </div>
          <div>
            <!-- 是否在线 -->
            <img style="width: 10px; height: 10px" v-if="item.online != '1'"
              :src="require('../../assets/img/online0.png')" />
            <img style="width: 10px; height: 10px" v-else :src="require('../../assets/img/online1.png')" />
          </div>
        </div>
        <div class="ico-list">
          <!-- 收藏 -->
          <div class="ico" @click="like(item)">
            <img v-if="item.following == '0'" :src="require('../../assets/img/ico01.png')" />
            <img v-else :src="require('../../assets/img/ico11.png')" />
          </div>
          <div class="ico" @click="message(item)">
            <img :src="require('../../assets/img/ico02.png')" />
          </div>
          <div class="ico" @click="email(item)" style="display: none">
            <img :src="require('../../assets/img/ico03.png')" />
          </div>
          <div class="ico" @click="gift(item)" style="display: none">
            <img :src="require('../../assets/img/ico04.png')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

// <script>
  // export default {
  //   data () {
  //     return {
  //       type: 0
  //     }
  //   }
  // }
  //
</script>
<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  import wx from 'weixin-js-sdk';
  export default {
    data() {
      return {
        type: 0, //顶部选项页的选中index
        memberList: [], //推荐的会员列表
        login: true,
        kh: "",
        userCode: "",
        userName: "",
        userTel: "",
        sitenum: "",
        sblx: "",
        headimg: "",
        height: this.getViewPortHeight(),
        languageLib: {
          lang_1: {
            online: "Online",
            lastest: "lastest",
            myFavor: "My Favor",
            likeMe: "Like Me",
            mutual: "Mutual",
          },
          lang_2: {
            online: "在线",
            lastest: "最新",
            myFavor: "我喜欢的",
            likeMe: "喜欢我的",
            mutual: "相互",
          },
          lang_3: {
            online: "在綫",
            lastest: "最新",
            myFavor: "我喜歡的",
            likeMe: "喜歡我的",
            mutual: "相互",
          },
        },
        currentLang: null,
      };
    },
    beforeMount() {
      const language_id = Cookies.get("language_id") || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib["lang_2"];
      console.log("global: ", this.$global.domain);
    },
    mounted() {
      var url = location.href;
      this.sitenum = getQueryVariable(url, "sitenum");
      this.kh = Cookies.get("kh");
      if (this.kh == undefined) {
        this.kh = "";
      }
      if (this.kh == "" || this.kh == null) {
        this.$router.push("login");
      } else {
        this.getUserInfo(); //获取用户信息，并且加载推荐的异性信息
        this.getWxConfig(); //加载js
      }
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页',  //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！',  //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' +  Cookies.get("kh"),   //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png'  //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });

        } else {
          Toast(result.msg);
        }
      },

      getViewPortHeight() {
        return (
          document.documentElement.clientHeight || document.body.clientHeight
        );
      },
      async getUserInfo() {
        //获取用户信息，如果没登录，则跳转到登录
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.shopList = result.data;
          that.userName = result.name;
          that.userTel = result.tel;
          that.headimg = result.avatar;
          if (result.sex == '保密') {
            //没有设置性别,就必须先设置了才允许
            Toast("请您先设置性别后,再进行访问");
            //延时跳转 让顾客看清提示
            setTimeout(() => {
              //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
              this.$router.push("setting");
              //延迟时间：3秒
            }, 500)
          }
          Cookies.set("sex", result.sex);

          //如果没有缴纳押金,跳转到支付押金页面
          if (result.kje <= 0) {
            //没有交押金,提示
            Toast("请您缴纳押金后,再进行访问");
            //延时跳转 让顾客看清提示
            setTimeout(() => {
              //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
              this.$router.push("topup");
              //延迟时间：3秒
            }, 500)
          }

          Cookies.set("balance", result.kje); //余额  押金,只要大于1,就是缴纳押金了

          //用户信息加载后，才可以知道这个人是男是女，然后推荐对应的
          //随机加载50个异性会员
          this.getUserList(0); //随机在线的用户
        } else {

          this.$confirm({
            message: result.msg,
            button: {
              no: "",
              yes: "我知道了"
            },
            callback: confirm => {
              console.log(confirm);
              if (confirm) {

              }
            }
          })


          //Toast(result.msg);
          //如果获取用户信息失败那就直接跳到登录
          //this.$router.push("login");
        }
      },
      async getUserList(type) {
        //判断一下，cookie如果丢失了，那么就重新登录
        if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
          //如果获取用户信息失败那就直接跳到登录
          this.$router.push("login");
		  return	;
        }

        //判断是否有查询参数,如果有,那么传值
        let url = location.href;
        let work_place = decodeURI(getQueryVariable(url, "work_place"));
        let birth_place = decodeURI(getQueryVariable(url, "birth_place"));
        //给用户推荐50个异性用户
        this.type = type; //顶部选项页的选中index
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserList2021",
          user: Cookies.get("kh"),
          sex: Cookies.get("sex"),
          type: type,
          search: "",
          birth_place: birth_place,
          work_place: work_place,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.memberList = result.data;
        } else {

        }
      },
      async like(item) {
        //喜欢 关注
        //不传值，直接根据数据库是否关注反向切换
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "collection2021",
          user: Cookies.get("kh"),
          code: item.kh, //user kh 都是会员的账号，有些接口之前用的是kh
          type: "2", //2是收藏会员，1是收藏商品
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //需要切换收藏图片状态
          if (item.following == 0) {
            item.following = "1";
          } else {
            item.following = "0";
          }
        } else {}
      },
      logout() {
        //退出登录
        let openid = Cookies.get("openid");
        Cookies.set("kh", "");
        Cookies.set("openid", "");
        this.$router.push("login?openid=" + openid);
      },
      message(item) {
        //消息中心
        this.$router.push({
          path: "/chat",
          query: {
            userCode: item.kh,
          },
        });
      },
      toSearch() {
        //打开搜索页面
        this.$router.push({
          path: "/search",
        });
      },
      email(item) {
        //邮件
        this.$router.push({
          path: "/reply",
          query: {
            userCode: item.kh,
          },
        });
      },
      gift(item) {
        //给这个会员送礼物
        this.$router.push({
          path: "/gift",
          query: {
            type: "sendgifts", //送礼状态下，不显示会员自己收到的礼物
            userCode: item.kh,
          },
        });
      },
      customerService() {
        //客服
        this.$router.push({
          path: "/reply",
          query: {
            userCode: "service",
          },
        });
      },
      viewDetails(item) {
        this.$router.push({
          path: "/userDet",
          query: {
            userCode: item.kh,
          },
        });
      },
      isMobile() {
        //用于判断当前设备是浏览器还是手机端，用于切换对应的页面入口
        var browser = {
          versions: (function() {
            var u = navigator.userAgent;
            return {
              trident: u.indexOf("Trident") > -1, //IE内核
              presto: u.indexOf("Presto") > -1, //opera内核
              webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
              gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
              mobile:
                !!u.match(/AppleWebKit.*Mobile.*/) ||
                (!!u.match(/AppleWebKit/) &&
                  u.indexOf("QIHU") &&
                  u.indexOf("QIHU") > -1 &&
                  u.indexOf("Chrome") < 0), //是否为移动终端
              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
              android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
              iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
              iPad: u.indexOf("iPad") > -1, //是否iPad
              webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
            };
          })(),
          language: (
            navigator.browserLanguage || navigator.language
          ).toLowerCase(),
        };
        console.log(
          "语言版本:" + browser.language,
          "是否为移动终端: " + browser.versions.mobile
        );
        return browser.versions.mobile;
      },
    },
  };
</script>

<style scoped>
  .home {
    padding: 50px 0;
  }

  .nav {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 50px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding-right: 50px;
  }

  .nav-list {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(153, 153, 153);
    font-size: 15px;
  }

  .nav-list .active {
    color: rgb(173, 93, 251);
    font-size: 17px;
  }

  .search {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }

  .person {
    padding: 10px;
    background-color: rgb(245, 245, 245);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item {
    width: 48.5%;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 10px 10px 0;
    margin-bottom: 10px;
  }

  .name {
    vertical-align: middle;
  }

  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ico-list {
    display: flex;
    justify-content: space-between;
  }

  .ico {
    padding: 5px;
  }

  .ico img {
    width: 24px;
    height: 24px;
  }

  .myPhoto {
    position: relative;
  }

  .myPhoto p {
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
