export default {
  //生产接口
  //domain: 'http://peng-mall.5izg.cn',
  domain: 'http://joyhome.winwinchina.com',
  wpturl: 'http://xcx02.keduozan.com',
  //本地接口
  //domain: 'http://localhost:46277',
  //wpturl: 'http://localhost:6379',
  refreshChatingMegIntervalSec: 3, //聊天获取消息的时间间隔 秒
  //appId: 'wx69ce098e9f602bbe',
  appId: 'wx37bab37d27501a2d',
  sitenum: '18777777777'
}
