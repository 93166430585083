<template>
  <div class="snav">
    <div class="snav-item" :class="{ active: item.page == $route.name }" v-for="item in config" :key="item.id"
      @click="topage(item.page)">
      <img :src="item.selectIcon" v-if="item.page == $route.name" />
      <img :src="item.icon" v-else />
      <div>
        {{ item.text }}
        <!-- 定时获取未读消息消息，并且显示在消息菜单下 -->
        <font v-if="item.page == 'news'" color="#f00">
          <a v-if="messageNum != '0'">{{ messageNum }}</a>
        </font>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  export default {
    name: "Stab",
    data() {
      return {
        messageNum: "0", //未读消息数字
      };
    },
    props: {
      config: {
        type: Array,
        default: [],
      },
    },
    mounted() {},
    methods: {
      topage(page) {
        if (page == 'href_about') {
          //打开第三方链接  首页的导航栏的关于是别的网址
          window.location.href =
            "https://mall.5izg.bj.cn/templates/tehui/article_list.html?shopid=18777777777&blname=关于我们";
        } else {
          this.$router.replace({
            name: page
          });
        }
        console.log("底部导航切换" + page);

        //刷新会员状态
        this.getUserInfo();
      },
      async getUserInfo() {
        //获取用户信息，如果没登录，则跳转到登录
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          token: Cookies.get("token"),
          sitenum: "18777777777",
        };
        //console.log(param);
        var result = await RequestApi.post(uri, param);
        //console.log(result);
        if (result.status == "1") {
          console.log("==================>未读消息数:" + result.messageNum);
          that.messageNum = result.messageNum; //顺便查询一下未读的消息数，显示出来
        } else {
          console.log("==================>用户没登录");
          this.$router.push("login");
        }
      },
    },
  };
</script>

<style scoped>
  .snav {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 65px;
    /*  客户要求工具栏高度加高 */
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    /* margin-top: 20px; */
    font-size: 10px;
    color: #999999;
    background-color: rgb(245, 245, 245);
    text-align: center;
  }

  .snav-item.active {
    color: rgb(173, 93, 251);
    /* margin-top: 6px; */
  }

  .snav-item img {
    height: 30px;
    width: 30px;
    margin-top: 6px;
  }
</style>
