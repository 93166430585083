<template>
  <div class="regin">
    <div class="title">更改密码</div>

    <div class="line">
      <span class="lable">原密码*</span>
      <input type="password" name="" v-model="pwdOld" />
    </div>
    <div class="line">
      <span class="lable">新密码*</span>
      <input type="password" v-model="pwd1" />
    </div>
    <div class="line">
      <span class="lable">确认新密码*</span>
      <input type="password" class="superIndent" v-model="pwd2" />
    </div>

    <div class="bottomBtn" @click="save()">修改密码</div>


  </div>
</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        userCode: "",
        pwdOld: "",
        pwd1: "",
        pwd2: "",
        languageLib: {
          lang_1: {
            password: 'password',
            passwordAgain: 'password again',
            tipPassword: 'Please Input Password',
            tipPwd2: 'Please Input Same Password',
            minPasswordLength: 'min Password Length 6',
          },
          lang_2: {
            password: '密码',
            passwordAgain: '再次输入密码',
            tipPassword: '请输入原密码',
            tipPwd2: '两次密码不一致',
            minPasswordLength: '密码长度最小6位',
          },
          lang_3: {
            password: '密碼',
            passwordAgain: '再次輸入密碼',
            tipPassword: '请输入原密码',
            tipPwd2: '兩次密碼輸入不一致',
            minPasswordLength: '最小密碼長度為6位',
          },
        }
      };
    },
    components: {
      [RadioGroup.name]: RadioGroup,
      [DatetimePicker.name]: DatetimePicker,
      [Radio.name]: Radio,
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2' ];
    },
    mounted() {
      this.userCode = Cookies.get("kh");
      //读出配置信息
      this.getMyInfo();
      document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
        // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
        WeixinJSBridge.call('hideOptionMenu');
      });
    },
    methods: {
      onFinish({
        selectedOptions
      }) {
        this.nationActive = false;
        this.nationText = selectedOptions.map((option) => option.text).join("/");
      },
      async save() {
        //修改密码
        if (this.pwdOld == "") {
          Toast(this.currentLang.tipPassword);
          return;
        };
        if (this.pwd1 <= 5) {
          Toast(this.currentLang.minPasswordLength);
          return;
        };
        if (this.pwd1 != this.pwd2) {
          Toast(this.currentLang.tipPwd2);
          return;
        };

        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "updatepwd2021",
          user: Cookies.get("kh"),
          useroldpwd: this.pwdOld, //先明码传输了
          userpwd: this.pwd1,
          yzm: "",
          tel: "",
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push("home");
            //延迟时间：3秒
          }, 500)
        } else {
          Toast(result.msg);
        }
      },
      async getMyInfo() {
        //获取自己的个人信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {

        } else {
          Toast(result.msg);
        }

      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        text-indent: 12px;
        color: #464646;
        text-indent: 70px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
