<template>
  <div class="bought">
    <van-nav-bar title="聊天" left-arrow @click-left="$router.go(-1)" />

    <div class="bought-cont">
      <div v-for="(item, index) in tabData[0]" :key="index" class="bought-cont-item" @click="checkItem(index)">
        <img class="bought-cont-img" :src="item.img" alt="">
        <div class="bought-cont-txtBox">
          <p>
            <img class="bought-cont-txtBox-tip" src="../../assets/img/callMe.png" alt="">{{item.name}}
          </p>
          <p>{{item.conteng}}</p>
        </div>
      </div>
      <div class="btn" @click="email()">发邮件</div>
    </div>

  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    data() {
      return {
        tabCur: 0,
        searchUser: '',
        showUser: true,
        tab: [{
            name: '邮件'
          },
          {
            name: '邮件'
          },
          {
            name: '客服'
          }
        ],
        userBtn: 0,
        userRow: {
          0: [

          ],
          1: [

          ]
        },
        userList1: [],
        tabData: {
          0: [

          ],
          1: [

          ],
          2: [

          ]
        }
      }
    },
    mounted() {
      this.getWxConfig(); //加载js
      var url = location.href;
      this.searchUser = getQueryVariable(url,
        "userCode");
      this.getEmailList(this.searchUser);
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      checkTab(cur) {
        console.log("==============>切换" + cur)
        this.tabCur = cur;
        //0 聊天  1 邮件 2客服
        if (cur == 1 || cur == 0) {
          this.showUser = true
        } else {
          this.showUser = false
        }

      },
      checkItem(cur) {

      },
      renderUser() {
        const userBtn = this.userBtn;
        if (userBtn == 1) {
          this.userBtn = 0
        } else {
          this.userBtn = 1
        }
      },
      email() {
        //消息中心
        this.$router.push({
          path: '/reply',
          query: {
            'userCode': this.searchUser
          }
        });
      },
      message(item) {
        //0 聊天  1 邮件 2客服
        console.log("===========>" + this.tabCur)
        switch (this.tabCur) {
          case 0:
            //消息中心
            this.$router.push({
              path: '/reply',
              query: {
                'userCode': item.kh
              }
            });
            break
          case 1:
            //消息中心
            this.$router.push({
              path: '/reply',
              query: {
                'userCode': item.kh
              }
            });
            break
          default:
            //这里是没有找到对应的值处理
            break
        }


      },
      async getEmailList(searchUser) {
        //发邮件列表
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post_2.ashx?action=getUemails2021";
        var param = {
          action: "getUemails2021",
          msgType: "2", //5是聊天  2是站内信
          page: '1',
          user: Cookies.get("kh"),
          queryUser: searchUser,
          mId: "1", //1,全部，2已读
          limit: 100,
          sitenum: '18777777777',
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log("================>接口返回");
        console.log(result);
        if (result.status == "1") {
          var data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                name: data[i].accept_user_name,
                type: data[i].is_read, //是否读了
                conteng: data[i].title,
                img: data[i].headImg,
              };
              that.tabData[0].push(sData);
            }
          }

        } else {
          //Toast(result.msg);
        }
      },

    }
  }
</script>

<style lang="less" scoped>
  .bought {
    &-head {
      &-one {
        padding: 10px 20px;
        position: relative;
      }

      &-left {
        display: flex;
      }

      &-search {
        position: absolute;
        top: 6px;
        right: 20px;
        width: 22px;
      }

      &-item {
        font-size: 12px;
        color: #232323;
        margin-right: 20px;
        cursor: pointer;

        &.cur {
          transform: scale(1.4);
          color: #E64454;
        }
      }

      &-two {
        position: relative;
        margin: 20px 20px 15px;
        font-size: 14px;

        &-title {
          color: #A9A9A9;
        }

        &-right {
          position: absolute;
          top: 0;
          right: 0;
          color: #8690DA;
          cursor: pointer;
        }
      }

      &-user {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &-item {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &-cont {
      border-top: 1px solid #F5F5F5;

      &-item {
        display: flex;
        padding: 15px;
        border-bottom: 1px solid #F5F5F5;
      }

      &-img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }

      &-txtBox {
        margin-left: 12px;
        padding-top: 10px;

        p {
          margin: 0;
          line-height: 1;

          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .bought-cont-txtBox-tip {
    width: 10px;
    margin-right: 4px;
  }

  .refIco {
    position: relative;
    top: 2px;
    left: -4px;
    width: 14px;
  }

  .btn {
    width: 92%;
    height: 13.867vw;
    background: #fd4c54;
    border-radius: 8vw;
    text-align: center;
    line-height: 13.867vw;
    color: #fff;
    font-size: 4.8vw;
    position: fixed;
    bottom: 2.667vw;
    margin-left: 4vw;
    margin-right: 4vw;

  }
</style>
