<template>
  <div class="vipMain">
    <div class="backHome">
      <span class="backIco" @click="back('main')"></span>
      <span class="backHomeIco" @click="back('home')"></span>
      {{currentLang.title}}
    </div>
    <div class="myGiftTitle">{{currentLang.imageList}}</div>
    <div class="giftBoxs cls">

      <div class="giftbox" v-for="gift in giftJson" :key="gift.id">
        <!-- @click="viewDetails(gift[0])" -->
        <span class="pic" :style="{ backgroundImage: 'url(' + gift.picPath + ')' }" style="background-size:cover;"></span>
        <!--        <span class="title">{{ gift.title }}</span>
        <span class="money">{{ gift.money }}金币</span> -->
        <span class="postGift" @click="delPhoto(gift)">{{currentLang.remove}}</span>
      </div>
    </div>
    <!--    <div class="myGiftTitle">私密相册</div>
   <div class="giftBoxs cls">
      <div class="giftbox" v-for="gift in gift1Json">
        <span class="pic" :style="{ backgroundImage: 'url(' + gift.picPath + ')' }"></span>
        <span class="postGift">删除</span>
      </div>
    </div> -->


    <div class="box flex flex__justify flex__middle">

      <div style="display:none;">
        <div class="flex flex__middle" @click.stop="uploadHeadImg">
          <van-image round width="3rem" height="3rem" :src="headimg" ref="photo1" />
        </div>
      </div>

      <!--      <div class="setting_right" @click.stop="uploadHeadImg">
        <div class="caption">上传照片</div>
      </div>
 -->

      <input type="file" accept="image/*" @change="handleFile" class="hiddenInput" style="display:none;" />
    </div>
    <div class="btn" style="margin:auto;text-align: center;" @click.stop="uploadHeadImg">{{currentLang.uploadPhoto}}</div>

  </div>

</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  export default {
    name: "",
    props: {},
    data() {
      return {
        now: 0,
        headimg: '',
        giftJson: [
          // {
          //   picPath: require("@/assets/img/gift1.png"),
          //   title: "普通相册1",
          //   money: "299",
          //   giftid: "123123",
          // },
          // {
          //   picPath: require("@/assets/img/gift2.png"),
          //   title: "普通相册2",
          //   money: "299",
          //   giftid: "123123",
          // },
          // {
          //   picPath: require("@/assets/img/gift3.png"),
          //   title: "普通相册3",
          //   money: "299",
          //   giftid: "123123",
          // },
          // {
          //   picPath: require("@/assets/img/gift4.png"),
          //   title: "普通相册4",
          //   money: "299",
          //   giftid: "123123",
          // }
        ],
        gift1Json: [{
            picPath: require("@/assets/img/gift1.png"),
            title: "隐私相册1",
            money: "299",
            giftid: "123123",
          },
          {
            picPath: require("@/assets/img/gift2.png"),
            title: "隐私相册2",
            money: "299",
            giftid: "123123",
          },
          {
            picPath: require("@/assets/img/gift3.png"),
            title: "隐私相册3",
            money: "299",
            giftid: "123123",
          }
        ],
        languageLib: {
          lang_1: {
            title: 'Album',
            imageList: 'Images',
            remove: 'remove',
            uploadPhoto: 'upload photo',
            imgMessage1: 'Only JPG/JPEG/PNG format permitted! ',
            imgMessage2: 'image size can\'t be more than 4MB!',
            removePhoto: {
              message: 'confirm remove photo? ',
              yesButton: 'Yes',
              noButton: 'No',
            }
          },
          lang_2: {
            title: '相册',
            imageList: '照片列表',
            remove: '删除',
            uploadPhoto: '上传照片',
            imgMessage1: '上传图片只能是 JPG/JPEG/PNG 格式!',
            imgMessage2: '上传图片大小不能超过 4MB!',
            removePhoto: {
              message: '确认删除照片?',
              yesButton: '确认',
              noButton: '取消',
            }
          },
          lang_3: {
            title: '相冊',
            imageList: '照片列表',
            remove: '刪除',
            uploadPhoto: '上傳照片',
            imgMessage1: '上传图片只能是 JPG/JPEG/PNG 格式',
            imgMessage2: '上传图片大小不能超过 4MB! ',
            removePhoto: {
              message: '確認刪除照片?',
              yesButton: '確認',
              noButton: '取消',
            }
          }
        },
        currentLang: {

        }

      };

    },
    components: {},
    beforeMount(){
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2' ];
    },
    mounted() {
      this.getWxConfig(); //加载js
      this.getPhotoList(1);
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      async viewDetails() {
        //查看照片
        this.$router.push({
          name: "photos",
        });
      },
      async delPhoto(item) {

        this.$confirm({
          message: this.currentLang.removePhoto.message,
          button: {
            no: this.currentLang.removePhoto.noButton,
            yes: this.currentLang.removePhoto.yesButton
          },
          callback: confirm=>{
            console.log(confirm);
            if (confirm) {
              //删除某一张照片
              var that = this;
              var uri = "";
              uri = this.$global.domain + "/tools/MallSelect.ashx";
              var param = {
                action: "delPhoto2021",
                user: Cookies.get("kh"),
                id: item.id,
                sitenum: '18777777777',
                token: Cookies.get("token"), //验证用户合法性
              };
              RequestApi.post(uri, param)
                  .then(result=>{
                    if (result.status == "1") {
                      Toast(result.msg);
                      location.reload(); //先刷新吧
                    } else {
                      Toast(result.msg);
                    }
                  })
            }
          }
        })
      },
      //点击 礼物类型，动态修改
      giftChangel(gt, i) {
        this.now = i
        console.log(gt.gTypeId, i);
      },
      async getPhotoList(type) {
        //获取指定会员的照片列表
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getPhotoList2021",
          user: Cookies.get("kh"),
          type: type,
          sitenum: '18777777777',
          token: Cookies.get("token"), //验证用户合法性
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          var data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                picPath: data[i].photoUrl,
                title: data[i].title,
                money: "0",
                id: data[i].id,
              };
              that.giftJson.push(sData);
            }
          }

        } else {
          Toast(result.msg);
        }
      },
      back(where) {
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        } else {
          this.$router.push({
            name: where,
          });
        }
        this.$router.push(-1);
      },
      // 打开图片上传
      uploadHeadImg: function() {
        this.$el.querySelector('.hiddenInput').click()
      },
      // 将头像显示
      handleFile: function(e) {
        let $target = e.target || e.srcElement
        let file = $target.files[0]
        var reader = new FileReader()
        reader.onload = (data) => {
          let res = data.target || data.srcElement
          this.headimg = res.result
        }
        const isImg = file.type === 'image/jpeg' || file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 10

        if (!isImg) {
          Toast('上传图片只能是 JPG/JPEG/PNG 格式!');
          return false
        }
        if (!isLt2M) {
          Toast('上传图片大小不能超过 4MB! 当前文件大小:' + file.size);
          return false
        }

        reader.readAsDataURL(file)
        //直接调用上传接口，将图片传给后台
        //要考虑文件加载时间,先做了一个延时
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.uploadImg(file);
          //延迟时间：秒
        }, 500)

      },
      //通过接口传图片
      async uploadImg(file) {
        var _this = this;
        let file1 = this.headimg; //获取文件数据
        var arr = [];
        arr.push({
          "imgurl": file1
        });
        // console.log(arr);
        var imglist = JSON.stringify(arr);
        var uri = "";
        uri = this.$global.domain + "/tools/mallsubmit.ashx";
        var param = {
          action: "saveImglist",
          user: Cookies.get("kh"),
          type: 1,  //1 传照片  2传头像
          imglist: imglist,
          sitenum: '18777777777',
          token: Cookies.get("token"), //验证用户合法性
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == 1) {
          const photoUrl = result.data;
          var sData = {
            picPath: photoUrl,
            title: '传照片',//TODO: 接口没有返回
            money: "0",
            id: 3543,//TODO: 接口没有返回  这个id是干什么????
          };
          this.giftJson.push(sData);
          Toast(result.msg);
        } else {
          Toast('照片上传失败');
        }
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  /*-------------------------------- */
  .scrollBar {
    width: 344px;
    position: relative;
    height: 34px;
    margin: 5px auto 30px auto;
    //   border: 1px red dashed;
    overflow: hidden;

    .scrollMain {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      /*第二层设置  不换行  */
      overflow-y: auto;
      height: 34px;
    }

    span {
      -webkit-overflow-scrolling: touch;
      line-height: 28px;
      height: 28px;
      width: 134px;
      border-radius: 10px;
      background-color: #f4f9fa;
      color: #444;
      margin-right: 16px;
      text-align: center;
      display: inline;
      position: relative;

      display: inline-block;
    }

    .giftActive {
      background-color: #ff4d54;
      color: white;
    }

    .scrollMain::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 100%;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 2px;
    }

    .scrollMain::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(248, 137, 118, 0.425);
      background: #535353;
    }

    .scrollMain::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(194, 194, 194, 0.4);
      border-radius: 10px;
      background: #ededed;
    }
  }

  .cls:after {
    content: "020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .cls {
    /* 触发 hasLayout */
    zoom: 1;
  }

  .giftBoxs {
    width: 344px;
    position: relative;
    min-height: 34px;
    margin: 5px auto 30px auto;

    //   border: 1px red dashed;
    .giftbox {
      width: 90px;
      height: 160px;
      float: left;
      // border: 1px pink dashed;
      display: block;
      position: relative;
      margin-left: 20px;
      margin-bottom: 12px;

      span {
        position: relative;
        //   border: 1px #9cc6ec dashed;
        display: inline-block;
      }

      .pic {
        top: 0px;
        height: 90px;
        width: 90px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: convert;
        border-radius: 5px;
      }

      .title {
        top: -3px;
        line-height: 14px;
        height: 14px;
        width: 88px;
        text-align: center;
        font-size: 12px;
        color: #222;
        font-weight: 600;
      }

      .money {
        top: -3px;
        line-height: 12px;
        height: 12px;
        width: 88px;
        text-align: center;
        font-size: 12px;
        color: #838383;
      }

      .postGift {
        top: 0px;
        line-height: 28px;
        height: 26px;
        width: 88px;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        font-size: 12px;
        color: #d23d5b;
        border: 1px #d23d5b solid;
      }
    }
  }

  .myGiftTitle {
    height: 34px;
    width: 375px;
    position: relative;
    line-height: 34px;
    text-align: left;
    margin: 0 auto;
    margin-top: 22px;
    background-color: white;
    text-align: left;
    font-size: 16px;
    text-indent: 15px;
    color: #222;
  }

  /*-------------------------------- */
  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }

  .btn {
    height: 40px;
    border-radius: 20px;
    background-color: rgb(178, 91, 252);
    line-height: 40px;
    margin-bottom: 20px;
  }
</style>
