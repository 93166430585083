import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import './assets/css/fn.less';
import './assets/css/iconfont.css';
// 引入style
import "@/style/base.less";
import {
  Toast
} from "vant";

// 注册全局组件
import CustomComponent from "./components";

//关闭或者打开vConsole
// import VConsole from 'vconsole'
// const vConsole = new VConsole();

// 引入vant组件
import "@/plugins/vant";
import VueConfirmDialog from 'vue-confirm-dialog'



// VUE_APP_MOCK的值为true，并且在开发环境下使用mock
if (process.env.VUE_APP_MOCK && process.env.NODE_ENV === "development") {
  require("../mock");
}

// 开发环境下面使用vConsole进行调试
// if (process.env.NODE_ENV === 'development') {
//   const VConsole = require('vconsole')
//   new VConsole()
// }

// 注册全局 filters
import filters from "@/filters";
import {
  RadioGroup,
  Radio,
  DatetimePicker,
  Popup,
  Cascader
} from "vant";
filters(Vue);

// 注册全局自定义指令
import "@/directives";
import global from './global'

// log
import {
  printANSI
} from "@/utils/screenLog";
printANSI();

Vue.use(CustomComponent, {});
Vue.use(VueConfirmDialog);
Vue.use(Popup);
Vue.use(Cascader);


Vue.config.productionTip = false;
Vue.prototype.$global = global;
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

new Vue({
  router,
  render: h => h(App),
  store
}).$mount("#app");
