<template>
  <div>
    <router-view />
    <stab :config="config"></stab>
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  data() {
    return {
      config: [
        {
          page: "select",
          icon: require("../../assets/img/home0.png"),
          selectIcon: require("../../assets/img/home1.png"),
          text: "",
        },
        {
          page: "href_about",
          icon: require("../../assets/img/act0-1.png"),
          selectIcon: require("../../assets/img/act1.png"),
          text: "",
        },
        {
          page: "news",
          icon: require("../../assets/img/new0.png"),
          selectIcon: require("../../assets/img/new1.png"),
          text: "",
        },
        {
          page: "main",
          icon: require("../../assets/img/main0.png"),
          selectIcon: require("../../assets/img/main1.png"),
          text: "",
        },
      ],
      languageLib: {
        lang_1: {
          main: "首页",
          timeline: "关于",
          message: "消息",
          mine: "我的",
        },
        lang_2: {
          main: "首页",
          timeline: "关于",
          message: "消息",
          mine: "我的",
        },
        lang_3: {
          main: "首頁",
          timeline: "关于",
          message: "消息",
          mine: "我的",
        },
      },
    };
  },
  methods: {
    getBarName() {
      var currentLang = Cookie.get("language_id") || 1;
      console.log(Cookie.get("language_id"));
      return this.languageLib["lang_" + currentLang];
    },
  },
  beforeMount() {
    var currentLang = this.getBarName();
    this.config[0].text = currentLang.main;
    this.config[1].text = currentLang.timeline;
    this.config[2].text = currentLang.message;
    this.config[3].text = currentLang.mine;
  },
};
</script>

<style scoped>
</style>
