<template>
  <div class="regin">
    <div class="title">搜索</div>
    <div class="line">
      <span class="lable">出生地</span>
      <input type="text" name="" v-model="birthPlace" @click="chooseBirthPlace=true" id="birth_place" ref="birth_place" />
    </div>
    <div class="line">
      <span class="lable">工作地</span>
      <input type="text" name="" v-model="workPlace" @click="chooseWorkPlace=true" id="work_place" ref="work_place" />
    </div>
    <div class="bottomBtn" @click="search">查询</div>
    <van-popup v-model="chooseBirthPlace" round position="bottom">
      <van-cascader
        v-model="chooseBirthPlace"
        title="请选择城市,点×可以完成选择"
        :options="quxianJson"
        @close="chooseBirthPlace = false;"
        @change="chooseBirthChange"
        @finish="chooseBirthPlace = false;"
      />
    </van-popup>
    <van-popup v-model="chooseWorkPlace" round position="bottom">
      <van-cascader
        v-model="choosedWorkPlace"
        title="请选择工作地"
        :options="quxianJson"
        @close="chooseWorkPlace = false;"
        @change="chooseWorkChange"
        @finish="chooseWorkPlace = false;"
      />
    </van-popup>
  </div>

</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import area from "@/utils/4-area.json";
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        quxianJson: [],
        chooseBirthPlace: false,
        chooseWorkPlace: false,
        workplace: '',
        userCode: "",
        work_place: "",
        birthPlace: "",
        workPlace: ''
      };
    },
    components: {
      [RadioGroup.name]: RadioGroup,
      [DatetimePicker.name]: DatetimePicker,
      [Radio.name]: Radio,
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
    },
    mounted() {
      this.getWxConfig(); //加载js
      this.quxianJson = this.parseArea();
      this.userCode = Cookies.get("kh");
      //读出配置信息
      this.getMyInfo();
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      parseArea(){
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        //console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseBirthChange({selectedOptions}){
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.birthPlace = areaName;
      },
      chooseWorkChange({selectedOptions}){
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.workPlace = areaName;
      },
      search() {
        //拼接查询条件,然后带着参数传回交友首页
        this.$router.push({
          name: "home",
          query: {
            'birth_place': this.birth_place,
            'work_place': this.work_place,
          }
        });
      },
      async getMyInfo() {
        //获取自己的个人信息 加载个人信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          this.email = result.email;
          this.country = result.address;
          this.wokrplace = result.wokrplace;
          if (result.sex == '女' || result.sex == '2') {
            this.sex = '2'; //会员的性别
          } else {
            this.sex = '1'; //会员的性别
          }
          // if (result.sex == "女") {
          //   this.sex = 2; //1男2女
          // } else {
          //   this.sex = 1;
          // }

        } else {
          Toast(result.msg);
        }

      },


    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        text-indent: 12px;
        color: #464646;
        text-indent: 70px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
