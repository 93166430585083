<template>
  <div class="login" :style="{ height: height + 'px' }">
    <div class="head">
      <div>请登录</div>
      <div style="font-size:16px;display:none;" @click="languageActive=true">{{currentLang.changeLanguage}}</div>
      <!--      <div style="font-size:16px" @click="changeLang(1, $event)">English</div>
      <div style="font-size:16px" @click="changeLang(2, $event)">简体中文</div>
      <div style="font-size:16px" @click="changeLang(3, $event)">繁體中文</div> -->
    </div>
    <div class="lovestory">
      <!-- 下面这句不要翻译 -->
      <span>快 乐 建 家</span>
    </div>
    <div class="ctx">

      <div class="ctx-item">
        <input type="text" ref="userCode" :placeholder="currentLang.inputPhone" /> <!-- 账号 -->
        <img class="ico" :src="require('../assets/img/login1.png')" />
      </div>
      <div class="ctx-item">
        <input type="password" ref="pwd" :placeholder="currentLang.inputPwd" /> <!-- 密码 -->
        <img class="ico" :src="require('../assets/img/login2.png')" />
      </div>
      <div class="forgot">
        <!-- <span>{{currentLang.forgetPwd}}</span>   @click="forgot" -->
      </div>
      <div class="btn" @click="login">{{currentLang.loginNow}}</div>
      <div>
        <!-- <span class="join" @click="regin">{{currentLang.registerNow}}</span> -->
        <!-- <span class="join" @click="customer_service">联系客服</span> -->
      </div>
    </div>
    <van-popup v-model="languageActive" position="bottom" close-on-click-overlay>
      <div class="languageBox">
        <p v-for="(yuyan,i) in languageJSON" @click="checkLanguage(yuyan,i)"
          :class="[yuyan.language_id == language_id? 'yuyanActive':'']">
          {{yuyan.text}}
        </p>

      </div>
    </van-popup>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Popup,
    Toast
  } from "vant";
  import axios from "axios";
  import qs from "qs";
  export default {
    data() {
      return {
        sitenum: '',
        openid: '',
        languageActive: false,
        language_id: 2, ////2022-3-9 按照客户要求默认改为简体字 语言id， 1，英文 2，简体中文 3，繁体中文
        languageJSON: [{
          language_id: 1,
          text: "English"
        }, {
          language_id: 2,
          text: "简体中文"
        }, {
          language_id: 3,
          text: "繁体中文"
        }],
        height: this.getViewPortHeight(),
        languageLib: {
          'lang_1': {
            pleaseLogin: 'Please Login',
            inputPhone: 'Please Input Username',
            inputPwd: 'Please Input Password',
            forgetPwd: 'Forget Password',
            loginNow: 'Login Now',
            registerNow: 'Register Now',
            changeLanguage: 'language'
          },
          'lang_2': {
            pleaseLogin: '请登录',
            inputPhone: '请输入账号',
            inputPwd: '请输入密码',
            forgetPwd: '忘记密码',
            loginNow: '登录',
            registerNow: '现在注册',
            changeLanguage: '切换'
          },
          'lang_3': {
            pleaseLogin: '請登錄',
            inputPhone: '請輸入賬號',
            inputPwd: '請輸入密碼',
            forgetPwd: '忘記密碼',
            loginNow: '登錄',
            registerNow: '現在注冊',
            changeLanguage: '切換'
          }
        },
        currentLang: null
      }

      that.userName = result.name;
      that.userAddress = result.address;
    },
    beforeMount() {
      console.log(location.href);
      if (location.href.indexOf('code=') > 0 && location.href.indexOf('?') < location.href.indexOf('#')) {
        const regex = /(.*)(\?.*)(#.*)/;
        const newUrl = location.href.replace(regex, '$1$3$2');
        console.log(newUrl);
        location.replace(newUrl);
        return;
      }

      //语言id， 1，英文 2，简体中文 3，繁体中文
      var url1 = location.href;
      this.language_id = getQueryVariable(url1, "language_id") || 2; //2022-3-9 按照客户要求默认改为简体字//20210617客户要求默认是繁体
      this.currentLang = this.languageLib['lang_' + this.language_id];
      console.log(this.$route);
      const weixinCode = this.$route.query['code'];
      if (weixinCode) {
        const param = {
          code: weixinCode,
          sitenum: this.$global.sitenum,
          action: 'setWxOauth2'
        };
        console.log(param);
        const url = this.$global.domain + '/tools/wxMessage_ajax.ashx?action=setWxOauth2';
        axios.post(url, qs.stringify(param))
          .then(res => {
            console.log(res.data);
            if (res.data.status == 1) {
              //已经注册了
              Cookies.set("kh", res.data.kh);
              Cookies.set("openid", res.data.openid);
              Cookies.set("token", res.data.token); //每次登陆，token会变化，并且根据有效期会超时
              Cookies.set("code", '86C64761F192EA595591B4D473E87F0C');
              /*if (result.msg1 != '') {
                //表示后台有话要和会员説，必须你要过期了
                Toast(result.msg1);
              }*/


              //跳转
              this.$router.push("home");
            } else {
              //按理说, 不会出现!=1的情况
            }
          });
      } else {
        //先注释掉,改别的
        // let redirectUrl = encodeURIComponent(location.href.replace(/(.*)(\/#.*)/,'$1'));
        // console.log(redirectUrl);
        // let weixinUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.$global.appId+'&redirect_uri='+redirectUrl+'&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
        // location.href = weixinUrl;
        // return;
      }
    },
    mounted() {
      if (this.isMobile()) {
        console.log("移动端");
      } else {
        console.log("pc端");
        //window.location.href = "http://www.anyaone.com/pc/#/signIn"
      }

      Cookies.set("language_id", "2"); //2022-3-9 按照客户要求默认改为简体字 //直接默认3 否则用户不切换语言，20210617客户要求默认是繁体
      var url = location.href;
      //商户号，这个本系统无效，有默认的
      this.sitenum = getQueryVariable(url,
        "sitenum");

      //判断一下,如果是本地地址,那么不跳转
      if (url.indexOf("192.168.1.104") > -1  || url.indexOf("192.168.31.223") > -1) {
        }
        else{
        //带着推荐人参数,直接自动注册
        window.location.href = this.$global.domain+ "/house/autoregister.aspx?sitenum=18777777777&r_id=" + Cookies
          .get(
            "r_id")
        //
      }


    },
    methods: {
      checkLanguage(yuyan, i) {
        this.language_id = yuyan.language_id;
        Cookies.set("language_id", this.language_id);
        this.currentLang = this.languageLib['lang_' + this.language_id];
        this.languageActive = false
      },
      getViewPortHeight() {
        return document.documentElement.clientHeight || document.body.clientHeight
      },
      async login() {
        if (this.$refs.userCode.value == "") {
          Toast("请输入账号");
          return;
        };
        if (this.$refs.pwd.value == "") {
          Toast("请输入密码");
          return;
        };
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "login",
          usernum: this.$refs.userCode.value,
          userpwd: this.$refs.pwd.value, //要加密一下
          sitenum: "18777777777",
          openid: "",
        };
        var result = await RequestApi.post(uri, param);

        if (result.status == "1") {
          Cookies.set("kh", this.$refs.userCode.value);
          Cookies.set("openid", result.openid);
          Cookies.set("token", result.token); //每次登陆，token会变化，并且根据有效期会超时
          Cookies.set("code", '86C64761F192EA595591B4D473E87F0C');
          if (result.msg1 != '') {
            //表示后台有话要和会员説，必须你要过期了
            Toast(result.msg1);
          }
          //跳转
          this.$router.push("home");
        } else {
          Toast(result.msg);
        }
        that.userName = result.name;
        that.userAddress = result.address;
      },
      async forgot() {
        this.$router.push("forgot");
      },
      regin() {
        this.$router.push("regin")
      },
      customer_service() {
        //改为调用企业微信客服
        window.location.href = "https://work.weixin.qq.com/kfid/kfc87b5402ee83a9aec";
		//this.$router.push("chat?userCode=service")
      },
      haoyou() {
        this.$router.push("friendList")
      },
      isMobile() {
        //用于判断当前设备是浏览器还是手机端，用于切换对应的页面入口
        var browser = {
          versions: function() {
            var u = navigator.userAgent;
            return {
              trident: u.indexOf('Trident') > -1, //IE内核
              presto: u.indexOf('Presto') > -1, //opera内核
              webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
              gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
              mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf(
                  'QIHU') &&
                u.indexOf('QIHU') > -1 && u.indexOf('Chrome') < 0, //是否为移动终端
              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
              android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
              iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
              iPad: u.indexOf('iPad') > -1, //是否iPad
              webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
            }
          }(),
          language: (navigator.browserLanguage || navigator.language).toLowerCase()
        };
        console.log("语言版本:" + browser.language, "是否为移动终端: " + browser.versions.mobile);
        return browser.versions.mobile;
      },
    },
    components: {
      [Popup.name]: Popup,
    }
  }
</script>

<style scoped>
  .languageBox {
    height: 100%;
    width: 100%;

  }

  .languageBox p {
    width: 80%;
    height: 30px;
    line-height: 30px;
    display: block;
    position: relative;
    margin: 0 auto;
    color: #666;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px #d4d4d4 solid;
  }

  .languageBox p:last-child {
    border-bottom: 1px transparent solid;

  }

  .yuyanActive {
    color: rgb(56, 140, 209) !important;
    font-weight: 600;
  }

  .login {
    min-height: 100vh;
    height: 100%;
    background: url("../assets/img/loginbg1.png");
    background-size: 100% 100%;
    color: #ffffff;
    box-sizing: border-box;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    font-size: 20px;
  }

  .ctx {
    margin-top: 35%;
    padding: 0 45px;
    text-align: center;
  }

  .ctx-item {
    position: relative;
    height: 40px;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 20px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .ctx-item input {
    background: none;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    line-height: 40px;
    font-size: 15px;
  }

  .ctx-item input::placeholder {
    color: #ffffff;
  }

  .ico {
    position: absolute;
    left: 20px;
    top: 10px;
    height: 20px;
    width: 20px;
  }

  .lovestory {
    margin-top: 25%;
    padding: 0 12vw;
    text-align: center;
    font-size: 40px;
  }

  .ctx-item+.ctx-item {
    margin-top: 30px;
  }

  .forgot {
    text-align: right;
    padding: 25px 10px 15px;
    font-size: 15px;
  }

  .btn {
    height: 40px;
    border-radius: 20px;
    background-color: rgb(178, 91, 252);
    line-height: 40px;
    margin-bottom: 20px;
  }

  .join {
    line-height: 30px;
    font-size: 15px;
    padding: 0 10px;
  }
</style>
