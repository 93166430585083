<template>
  <div class="main" :style="{ height: height + 'px' }">
    <div class="nav">
      <div class="nav-item" @click="message()">
        <!-- 消息 -->
        <img :src="require('../../assets/img/mynotice.png')" />
        <font color="#f00">
          <!--     {{messageNum}} -->
          <a v-if="messageNum != '0'">{{messageNum}}</a>
        </font> <!-- 未读消息数 -->
      </div>
      <div class="nav-item" @click="showMe()">
        <!-- 个人设置 -->
        <img :src="require('../../assets/img/myselect.png')" />
      </div>
    </div>
    <div class="user">
      <img class="headimg" :src="imgUrl" />
      <div>
        <div>
          {{userName}}
        </div>
        <div class="sex">
          <img style="" v-if="userSex == '2'" :src="require('../../assets/img/mysex0.png')" alt="" />
          <img style="" v-else :src="require('../../assets/img/mysex1.png')" alt="" />
          <!--       <span>{{userSex}}</span> -->
          <span>{{userAge}}</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="vip">
      <!-- 会员等级 -->
      <div>{{userGrade}}</div>
      <!-- 会员有效期 -->
      <div>{{currentLang.expire}}:{{expiringDate}}</div>
    </div>
    <div class="data">
      <!-- 访客人数 -->
      <div class="data-item" @click="myAct(5)">
        <div class="num">{{visitorsNum}}</div>
        <div>{{currentLang.guest}}</div>
      </div>
      <div class="data-item" @click="my_integral()">
        <!-- 最新动态 -->
        <div class="num">{{userIntegral}}</div>
        <div>积分</div>
      </div>

      <div class="data-item">
        <div class="num">{{userBalance}}</div>
        <div>婚恋押金</div>
      </div>

    </div>

    <div class="list">

      <div class="item" @click="showMe()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my4.png')" alt="" />
          </span>
          <span>我的资料</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>

      <div class="item" @click="recharge()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my1.png')" alt="" />
          </span>
          <span>婚恋押金</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
      <!--      <div class="item" @click="upgrade()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my2.png')" alt="" />
          </span>
          <span>{{currentLang.upgrade}}</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div> -->
      <div class="item" @click="gift()" style="display:none;">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my3.png')" alt="" />
          </span>
          <span>{{currentLang.gift}}</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
      <div class="item" @click="albums()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my4.png')" alt="" />
          </span>
          <span>{{currentLang.album}}</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
      <div class="item" @click="myHouse()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my3.png')" alt="" />
          </span>
          <span>我发布的房产</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
      <div class="item" @click="myLikeHouse()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my5.png')" alt="" />
          </span>
          <span>我关注的房产</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
      <div class="item" @click="myJob()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my2.png')" alt="" />
          </span>
          <span>我发布的招聘</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
      <div class="item" @click="myLikeJob()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my5.png')" alt="" />
          </span>
          <span>我关注的招聘</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>

      <!--      <div class="item" @click="get_qr_code()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my5.png')" alt="" />
          </span>
          <span>分享海报</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div> -->

      <!--      <div class="item" @click="video()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my5.png')" alt="" />
          </span>
          <span>视频</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div> -->
      <div class="item" @click="customerService()">
        <div>
          <span>
            <img class="item-img" :src="require('../../assets/img/my6.png')" alt="" />
          </span>
          <span>{{currentLang.customerService}}</span>
        </div>
        <div>
          <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="item">
    </div>
    <div class="item">
    </div>
    <!--    <div class="item" @click="logout()">
      <div>
        <span>
          <img class="item-img" :src="require('../../assets/img/my5.png')" alt="" />
        </span>
        <span>{{currentLang.logout}}</span>
      </div>
      <div>
        <img style="widht:15px;height:15px" :src="require('../../assets/img/to.png')" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  import wx from 'weixin-js-sdk';
  export default {
    data() {
      return {
        login: true,
        kh: '',
        userName: '',
        imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png',
        userBalance: '0',
        visitorsNum: '0', //访客数，来看看的数量
        dynamicNum: '0', //动态数
        messageNum: '0', //未读消息数字
        userTel: '',
        expiringDate: '', //失效日期
        userGrade: '', //会员类型
        userSex: '',
        userAge: '',
        sitenum: '',
        userIntegral: '', //会员的积分
        sblx: '',
        headimg: '',
        height: this.getViewPortHeight(),
        languageLib: {
          lang_1: {
            expire: 'expire at',
            guest: 'guest',
            timeline: 'timeline',
            balance: 'balance',
            recharge: 'rechage',
            upgrade: 'upgrade',
            gift: 'gift',
            album: 'album',
            customerService: 'customer service',
            logout: 'logout'
          },
          lang_2: {
            expire: '到期',
            guest: '访客',
            timeline: '关于',
            balance: '账户余额',
            recharge: '交押金',
            upgrade: '升级',
            gift: '礼物',
            album: '相册',
            customerService: '客服',
            logout: '退出'
          },
          lang_3: {
            expire: '到期',
            guest: '訪客',
            timeline: '最新動態',
            balance: '賬戶餘額',
            recharge: '交押金',
            upgrade: '升級',
            gift: '禮物',
            album: '相冊',
            customerService: '客服',
            logout: '登出'
          },
          currentLang: null
        }
      }
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2'];
    },
    activated() {
      //this.getWxConfig(); //加载js
    },
    mounted() {

      if (this.isMobile()) {
        console.log("移动端");
      } else {
        console.log("pc端");
        //window.location.href = "http://www.anyaone.com/pc/#/signIn"
      }

      var url = location.href;
      this.sitenum = getQueryVariable(url,
        "sitenum");
      this.kh = Cookies.get("kh");
      if (this.kh == undefined) {
        this.kh = "";
      }
      if (this.kh == "" || this.kh == null) {
        console.log("==================>用户没登录")
        this.$router.push("login");
      } else {
        this.getUserInfo();
      }
      this.getWxConfig(); //加载js



    },
    computed: {
      //给头像传值
      // imgUrl: function() {
      //   return this.headimg;
      // }
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });

          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
            wx.hideMenuItems({
              menuList: ["menuItem:copyUrl", "menuItem:openWithQQBrowser", "menuItem:openWithSafari",
                "menuItem:share:email", "menuItem:share:brand", "menuItem:share:qq", "menuItem:share:QZone"
              ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            });
          });
        } else {
          Toast(result.msg);
        }
      },


      getViewPortHeight() {
        return document.documentElement.clientHeight || document.body.clientHeight
      },
      async getUserInfo() {
        //获取用户信息，如果没登录，则跳转到登录
        var that = this;
        var uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          token: Cookies.get("token"),
          sitenum: '18777777777'
        };
        console.log(param);
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.shopList = result.data;
          that.userName = result.name;
          that.userTel = result.tel;
          that.userBalance = result.kje; //会员的账户余额
          that.userIntegral = result.kjf; //会员的账户积分
          that.visitorsNum = result.visitorsNum; //访客数，来看看的数量
          that.dynamicNum = result.dynamicNum; //动态数
          that.messageNum = result.messageNum; //顺便查询一下未读的消息数，显示出来
          //that.userAge = result.userAge;  //会员的岁数
          that.userGrade = result.fklx;
          if (result.sex == '女' || result.sex == '2') {
            that.userSex = '2'; //会员的性别
          } else {
            that.userSex = '1'; //会员的性别
          }

          that.expiringDate = result.sxrq; //会员的岁数
          //that.imgUrl = require(result.avatar);
          that.imgUrl = result.avatar;
          //that.headimg = result.avatar;
        } else {
          console.log("==================>用户没登录")
          this.$router.push("login");
        }
      },
      logout() {
        //退出登录
        let openid = Cookies.get("openid");
        Cookies.set("kh", "");
        Cookies.set("openid", "");
        this.$router.push("login?openid=" + openid);

      },
      message() {
        //消息中心
        this.$router.push({
          path: '/news',
          query: {

          }
        });
      },
      myAct(type) {
        //最新动态
        this.$router.push({
          path: '/myAct',
          query: {
            type: type
          }
        });
      },
      my_integral() {
        //查询积分获得记录
        this.$router.push({
          path: '/my_integral'
        });
      },
      get_qr_code() {
        //获取分享二维码
        this.$router.push({
          path: '/image'

        });

      },
      customerService() {
        //改为调用企业微信客服
        window.location.href = "https://work.weixin.qq.com/kfid/kfc87b5402ee83a9aec";
        // if (this.kh == "service") {
        //   Toast("客服不能和自己聊天");

        // } else {
        //   //客服
        //   this.$router.push({
        //     path: '/chat',
        //     query: {
        //       'userCode': 'service',
        //     }
        //   });
        // }
      },
      showMe() {
        //个人资料  myData
        this.$router.push({
          path: '/setting',
          query: {
            'sitenum': this.sitenum
          }
        });
      },
      albums() {
        //相册
        this.$router.push({
          path: '/albums',
          query: {
            'sitenum': this.sitenum
          }
        });
      },
      myJob() {
        //我发布的招聘信息
        this.$router.push({
          path: '/my_job',
          // query: {
          //   'sitenum': this.sitenum
          // }
        });
      },
      myHouse() {
        //我发布的房屋信息
        this.$router.push({
          path: '/my_house_list',
          // query: {
          //   'sitenum': this.sitenum
          // }
        });
      },
      myLikeHouse() {
        //我关注的房屋信息
        this.$router.push({
          path: '/my_like_house',
        });
      },
      myLikeJob() {
        //我关注的招聘求职信息
        this.$router.push({
          path: '/my_like_job',
        });
      },
      gift() {
        //礼物
        this.$router.push({
          path: '/gift',
          query: {
            'sitenum': this.sitenum
          }
        });
      },
      upgrade() {
        //升级
        this.$router.push({
          path: '/upgrade',
          query: {
            'sitenum': this.sitenum
          }
        });
      }, //
      recharge() {
        //充值
        this.$router.push({
          path: '/topup',
          query: {
            'sitenum': this.sitenum
          }
        });
      },
      isMobile() {
        //用于判断当前设备是浏览器还是手机端，用于切换对应的页面入口
        var browser = {
          versions: function() {
            var u = navigator.userAgent;
            return {
              trident: u.indexOf('Trident') > -1, //IE内核
              presto: u.indexOf('Presto') > -1, //opera内核
              webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
              gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
              mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf(
                  'QIHU') &&
                u.indexOf('QIHU') > -1 && u.indexOf('Chrome') < 0, //是否为移动终端
              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
              android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
              iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
              iPad: u.indexOf('iPad') > -1, //是否iPad
              webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
            }
          }(),
          language: (navigator.browserLanguage || navigator.language).toLowerCase()
        };
        console.log("语言版本:" + browser.language, "是否为移动终端: " + browser.versions.mobile);
        return browser.versions.mobile;
      },

      // end
    },
  }
</script>

<style scoped>
  .main {
    background: url('../../assets/img/mybg.png');
    background-size: 100% auto;
    background-color: #ffffff;
    background-repeat: no-repeat;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
  }

  .nav-item {
    padding: 10px;
  }

  .nav-item img {
    width: 30px;
    height: 30px;
  }

  .user {
    margin: 8px 15px 17px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
  }

  .headimg {
    border: 2px solid #ffffff;
    box-sizing: border-box;
    background: white;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    width: 62px;
    height: 62px;
    font-size: 0;
  }

  .sex {
    margin-top: 6px;
    width: 60px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    background-color: #ffffff;
    color: rgb(255, 77, 148);
    font-size: 14px;
    line-height: 20px;
  }

  .sex img {
    height: 14px;
    width: 14px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .sex span {
    vertical-align: middle;
  }

  .line {
    margin: 0 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }

  .vip {
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 35px;
    padding: 0 10px;
  }

  .data {
    margin: 0 15px;
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    height: 75px;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 1px rgba(200, 200, 200, 0.4);
    line-height: 30px;
    padding: 10px 0;
    box-sizing: border-box;
    margin-bottom: 20px;
    text-align: center;
  }

  .data-item {
    flex: 1;
    color: rgb(122, 122, 122);
    font-size: 15px;
  }

  .num {
    color: rgb(173, 93, 251);
    font-size: 18px;
    font-weight: bold;
  }

  .item {
    padding: 0 20px;
    font-size: 15px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item:active {
    background-color: #f7f7f7;
  }

  .item span {
    vertical-align: middle;
  }

  .item-img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 10px;
  }
</style>
