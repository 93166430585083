<template>
  <div class="regin">
    <div class="title">修改信息</div>
    <!--    <div class="line">
      <span class="lable">用户名*</span>
      <input type="text" name="" v-model="userCode" id="userCode" ref="userCode" />
    </div> -->
    <!-- <div class="line">
      <span class="lable">邮箱*</span>
      <input type="text" name="" v-model="email" id="email" ref="email" />
    </div> -->
    <!--    <div class="line">
      <span class="lable">密码*</span>
      <input type="password"  name="" id="pwd"  ref="pwd" />
    </div>
    <div class="line">
      <span class="lable">再次输入密码*</span>
      <input class="superIndent" type="password"  name="" id="pwd2"  ref="pwd2" />
    </div> -->
    <div class="line">
      <span class="lable">昵称</span>
      <input type="text" name="" v-model="userName" id="userName" ref="userName" />
    </div>
    <div class="line">
      <span class="lable">性别</span>
      <van-radio-group class="sexGroup" v-model="sex" ref="sex" icon-size="16px" checked-color="#AB856F"
        direction="horizontal">
        <van-radio name="1">男</van-radio>
        <van-radio name="2">女</van-radio>
      </van-radio-group>
    </div>
    <div class="line">
      <span class="lable">手机</span>
      <input type="number" name="" v-model="tel" id="tel" ref="tel" />
    </div>
    <div class="line" @click="birthdayActive = true">
      <span class="lable">出生日期</span>
      <span class="suojin">{{ birthdayText }}</span>
    </div>
    <div class="line">
      <span class="lable">出生地</span>
      <input type="text" name="" v-model="country" id="country" ref="country" @click="chooseBirthPlace=true;" />
    </div>
    <div class="line">
      <span class="lable">工作地</span>
      <input type="text" name="" v-model="workplace" id="workplace" ref="workplace" @click="chooseWorkPlace = true;" />
    </div>
    <div class="line">
      <span class="lable">自我简介</span>
      <input type="text" name="" v-model="introduction" />
    </div>
    <div class="bottomBtn" @click="save()">保存</div>

    <!--生日选择选择-->
    <van-popup v-model="birthdayActive" position="bottom" close-on-click-overlay>
      <van-datetime-picker @confirm="selBirthDay" @cancel="birthdayActive = false" v-model="birthday" type="date"
        title="选择年月日" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
    <van-popup v-model="chooseBirthPlace" round position="bottom">
      <van-cascader v-model="choosedBirthPlace" title="请选择城市,点×可以完成选择" :options="quxianJson"
        @close="chooseBirthComplete" @change="chooseBirthChange" @finish="onBirthFinish" />
    </van-popup>
    <van-popup v-model="chooseWorkPlace" round position="bottom">
      <van-cascader v-model="choosedWorkPlace" title="请选择工作地" :options="quxianJson" @close="chooseWorkComplete"
        @change="chooseWorkChange" @finish="onWorkFinish" />
    </van-popup>
  </div>
</template>


<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader,
    Toast
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import area from '@/utils/4-area.json'
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        choosedBirthPlace: '',
        choosedWorkPlace: '',
        chooseBirthPlace: false,
        choosePlace: false, //选择出生地
        chooseWorkPlace: false, //选择工作地
        userCode: "",
        userName: "", //昵称
        tel: "",
        sex: "1",
        email: "1",
        birthday: new Date(2001, 1, 15),
        maxDate: new Date(),
        minDate: new Date(1900, 1, 1),
        birthdayActive: false,
        birthdayText: "点击选择生日...",
        nationActive: false,
        country: "", //出生地
        workplace: "", //工作地,有查询功能
        introduction: "", //自我介绍
        card: "",
        khname: "",
        bankname: "",
        banknum: "",
        tjr: "",
        radio: '1',
        quxianJson: [],


      };
    },
    components: {
      [RadioGroup.name]: RadioGroup,
      [DatetimePicker.name]: DatetimePicker,
      [Radio.name]: Radio,
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
    },
    mounted() {
      this.quxianJson = this.parseArea();
      this.userCode = Cookies.get("kh");
      //读出配置信息
      this.getMyInfo();
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      parseArea() {
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        //console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseBirthComplete() {
        this.chooseBirthPlace = false;
      },
      chooseBirthChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.country = areaName;
      },
      onBirthFinish({
        selectedOptions
      }) {
        this.chooseBirthPlace = false;
      },
      chooseWorkComplete() {
        this.chooseWorkPlace = false;
      },
      chooseWorkChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.workplace = areaName;
      },
      onWorkFinish({
        selectedOptions
      }) {
        this.chooseWorkPlace = false;
      },
      async save() {
        //保存本次修改的个人信息
        var sex1 = ""; //1男2女
        if (this.sex == "2") {
          sex1 = '女'; //1男2女
        } else {
          sex1 = '男';
        }

        //获取自己的个人信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/mallsubmit.ashx";
        var param = {
          action: "updateUserInfo",
          user: Cookies.get("kh"),
          name: this.userName,
          tel: this.tel,
          birthday: this.birthdayText, //生日
          sex: sex1, //性别   数据库存的是汉字
          email: this.email, //邮箱
          address: this.country, //国家  出生地 会员信息表存地址字段内
          workplace: this.workplace, //工作地
          introduction: this.introduction, //自我介绍
          card: this.card, //这值本界面不修改,但是要读出并且传值
          khname: this.khname, //这值本界面不修改,但是要读出并且传值
          bankname: this.bankname, //这值本界面不修改,但是要读出并且传值
          banknum: this.banknum, //这值本界面不修改,但是要读出并且传值
          tjr: this.tjr, //这值本界面不修改,但是要读出并且传值
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push("home");
            //延迟时间：3秒
          }, 500)
        } else {
          Toast(result.msg);
        }
      },
      async getMyInfo() {
        //获取自己的个人信息 加载个人信息
        let that = this;
        let uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.email = result.email;
          that.country = result.address;
          that.workplace = result.workplace;
          that.userName = result.name;
          that.tel = result.tel;
          that.birthdayText=result.birthday;
          that.introduction = result.introduction; //个人简介
          if (result.sex == '女' || result.sex == '2') {
            that.sex = '2'; //会员的性别
          } else {
            that.sex = '1'; //会员的性别
          }
          // if (result.sex == "女") {
          //   this.sex = 2; //1男2女
          // } else {
          //   this.sex = 1;
          // }
          that.card = result.card;
          that.khname = result.khname;
          that.bankname = result.bankname;
          that.banknum = result.banknum;
          that.tjr = result.tjr;
        } else {
          Toast(result.msg);
        }

      },
      // 选项格式化函数
      formatter(type, value) {
        if (type === "year") {
          return `${value}年`;
        } else if (type === "month") {
          return `${value}月`;
        } else if (type === "day") {
          return `${value}日`;
        } else if (type === "hour") {
          return `${value}时`;
        } else if (type === "minute") {
          return `${value}分`;
        } else if (type === "second") {
          return `${value}秒`;
        }
        return value;
      },
      selBirthDay(val) {
        let year = val.getFullYear();
        let month = val.getMonth() + 1;
        let day = val.getDate();
        let hour = val.getHours();
        let minute = val.getMinutes();
        if (month >= 1 && month <= 9) {
          month = `0${month}`;
        }
        if (day >= 1 && day <= 9) {
          day = `0${day}`;
        }
        if (hour >= 0 && hour <= 9) {
          hour = `0${hour}`;
        }
        if (minute >= 0 && minute <= 9) {
          minute = `0${minute}`;
        }
        //        this.birthdayText = `${year}-${month}-${day} ${hour}:${minute}`;
        this.birthdayText = `${year}-${month}-${day}`;
        this.birthdayActive = false;
        console.log(this.birthdayText);
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        text-indent: 12px;
        color: #464646;
        text-indent: 70px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
