<template>
  <div class="login" :style="{height:height+'px'}">
    <div class="head">
      <div>{{currentLang.forget}}</div>
      <div style="font-size:16px"></div>
    </div>
    <div class="ctx">
      <div class="ctx-item">
        <input type="text" ref="email" :placeholder="currentLang.inputEmail" />
        <img class="ico" :src="require('../assets/img/login1.png')" />
      </div>
      <div class="forgot">
        <span></span>
      </div>
      <div class="btn" @click="forgot()">Go</div>
<!--      <div>
        <span class="join" @click="regin">{{currentLang.register}}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    data() {
      return {
        sitenum: '',
        openid: '',
        height: this.getViewPortHeight(),
        languageLib: {
          'lang_1': {
            forget: 'Forgot Password',
            inputEmail: 'Input Email',
            register: 'Register',
            tipEmail: 'Please Input Email',
            tipEmailNotPass: 'check email failed',
          },
          'lang_2': {
            forget: '忘记密码',
            inputEmail: '请输入邮箱',
            register: '现在加入',
            tipEmail: '请输入邮箱',
            tipEmailNotPass: '邮箱格式不正确',
          },
          'lang_3': {
            forget: '忘記密碼',
            inputEmail: '請輸入郵箱',
            register: '現在加入',
            tipEmail: '請輸入郵箱',
            tipEmailNotPass: '郵箱驗證不通過',
          }
        },
        currentLang: 2
      }
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2' ];
    },
    mounted() {
      var url = location.href;
      this.sitenum = getQueryVariable(url,
        "sitenum");
    },
    methods: {
      getViewPortHeight() {
        return document.documentElement.clientHeight || document.body.clientHeight
      },
      async forgot() {
        if (this.$refs.email.value == "") {
          //Toast("请输入正确的邮箱");
          Toast(this.currentLang.tipEmail);
          return;
        };
        var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
        if (re.test(this.$refs.email.value)) {

        } else {
          Toast(this.currentLang.tipEmailNotPass);
          return false;
        }
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "login",
          email: this.$refs.email.value,
          sitenum: "18777777777",
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          // Cookies.set("kh", this.$refs.userCode.value);
          // Cookies.set("openid", this.openid);
          // Cookies.set("token", this.token); //每次登陆，token会变化，并且根据有效期会超时
          // Cookies.set("code", '86C64761F192EA595591B4D473E87F0C');
          // //跳转
          // this.$router.push("login");
        } else {
          Toast("重置的密码已经发入您的邮箱");

        }

      },
      regin() {
        this.$router.push("regin")
      },
    }
  }
</script>

<style scoped>
  .login {
    min-height: 100vh;
    height: 100%;
    background: url('../assets/img/loginbg1.png');
    background-size: 100% 100%;
    color: #ffffff;
    box-sizing: border-box;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    font-size: 20px;
  }

  .ctx {
    margin-top: 50%;
    padding: 0 45px;
    text-align: center;
  }

  .ctx-item {
    position: relative;
    height: 40px;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 20px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .ctx-item input {
    background: none;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    line-height: 40px;
    font-size: 15px;
  }

  .ctx-item input::placeholder {
    color: #FFFFFF;
  }

  .ico {
    position: absolute;
    left: 20px;
    top: 10px;
    height: 20px;
    width: 20px;
  }

  .ctx-item+.ctx-item {
    margin-top: 30px;
  }

  .forgot {
    text-align: right;
    padding: 25px 10px 15px;
    font-size: 15px;
  }

  .btn {
    height: 40px;
    border-radius: 20px;
    background-color: rgb(178, 91, 252);
    line-height: 40px;
    margin-bottom: 20px;
  }

  .join {
    line-height: 30px;
    font-size: 15px;
    padding: 0 10px;
  }
</style>
