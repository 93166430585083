import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 为了首屏加载快，所以首页不使用懒加载
import index from '../views/index/index.vue'
import home from '../views/index/home.vue'
import act from '../views/index/act.vue'
import news from '../views/index/news.vue'
import main from '../views/index/main.vue'
import login from '../views/login.vue'
import forgot from '../views/forgot.vue'
import myData from '../views/index/myData.vue'
import userDet from '../views/index/userDet.vue'
import newsList from '../views/index/newsList.vue'
import albums from '../views/index/albums.vue'
import myAct from '../views/index/myAct.vue'
import search from '../views/index/search.vue'
import myPwdChange from '../views/index/myPwdChange.vue'

// 自动扫描 modules 里面的路由模块，路由模块请根据业务自行拆分
// const files = require.context('./modules', false, /\.js$/)//这里少东西了
const routes = []
// 获取所有的路由内容
// files.keys().forEach(key => {
//   const file = files(key).default
//   // 根据导出的内容判断是否数组，如果数组需使用扩展运算符
//   if (Array.isArray(file)) {
//     routes.push(...file)
//   } else {
//     routes.push(file)
//   }
// })

routes.push(
  ...[{
      path: '/',
      name: 'index',
      component: index,
      redirect: '/home',
      children: [{
          path: '/home',
          name: 'home',
          component: home,
          meta: {
            title: '交友'
          }
        },

        // {
        //   path: '/act',
        //   name: 'act',
        //   component: act,
        //   meta: {
        //     title: '最新动态'
        //   }
        // },
        {
          path: '/href_about',
          name: 'href_about',
          component: act,
          meta: {
            title: '关于'
          }
        },
        {
          path: '/news',
          name: 'news',
          component: news,
          meta: {
            title: '消息'
          }
        },
        {
          path: '/main',
          name: 'main',
          component: main,
          meta: {
            title: '快乐建家 我的'
          }
        },
        {
          path: '/myData',
          name: 'myData',
          component: myData,
          meta: {
            title: '我的资料'
          }
        },
        {
          path: '/friendList',
          name: 'friendList',
          component: () => import('@/views/friendList/index.vue'),
          meta: {
            title: '快乐建家 friends'
          }
        },
        {
          path: '/select',
          name: 'select',
          component: () => import('@/views/select/select.vue'),
          meta: {
            title: '选择模式'
          }
        },
      ]
    },
    // {
    //   path: '/select',
    //   name: 'select',
    //   component: () => import('@/views/select/select.vue'),
    //   meta: {
    //     title: '选择模式'
    //   }
    // },
    {
      path: '/reply',
      name: 'reply',
      component: () => import('@/views/reply/index.vue'),
      meta: {
        title: '快乐建家'
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/setting/index.vue'),
      meta: {
        title: '快乐建家'
      }
    },
    {
      path: '/topup',
      name: 'topup',
      component: () => import('@/views/topup/index.vue'),
      meta: {
        title: '快乐建家 '
      }
    },
    {
      path: '/myPwdChange',
      name: 'myPwdChange',
      component: myPwdChange,
      meta: {
        title: 'change pwd'
      }
    },
    // {
    //   path: '/paypal',
    //   name: 'paypal',
    //   component: () => import('@/views/topup/paypal.vue'),
    //   meta: {
    //     title: '快乐建家 topup'
    //   }
    // },
    {
      path: '/upgrade',
      name: 'upgrade',
      component: () => import('@/views/upgrade/index.vue'),
      meta: {
        title: '快乐建家 upgrade'
      }
    },
    {
      path: '/gift',
      name: 'gift',
      component: () => import('@/views/gift/index.vue'),
      meta: {
        title: '快乐建家 gift'
      }
    },
    {
      path: '/giveGift',
      name: 'giveGift',
      component: () => import('@/views/gift/giveGift.vue'),
      meta: {
        title: '快乐建家 gift'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        title: '快乐建家 login'
      }
    },
    {
      path: '/regin',
      name: 'regin',
      component: () => import('@/views/regin/index.vue'),
      meta: {
        title: '快乐建家 登录'
      }
    },
    {
      path: '/details',
      name: 'details',
      component: () => import('@/views/regin/details.vue'),
      meta: {
        title: '快乐建家'
      }
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: forgot,
      meta: {
        title: '快乐建家 forgot'
      }
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/views/chat/index.vue'),
      meta: {
        title: '快乐建家 chat'
      }
    },
    {
      path: '/userDet',
      name: 'userDet',
      component: userDet,
      meta: {
        title: '快乐建家'
      }
    },
    {
      path: '/newsList',
      name: 'newsList',
      component: newsList,
      meta: {
        title: '快乐建家 mail'
      }
    },
    {
      path: '/albums',
      name: 'albums',
      component: albums,
      meta: {
        title: '快乐建家 albums'
      }
    },
    {
      path: '/myAct',
      name: 'myAct',
      component: myAct,
      meta: {
        title: '快乐建家'
      }
    },
    {
      path: '/search',
      name: 'search',
      component: search,
      meta: {
        title: '搜索会员'
      }
    },
    {
      path: '/myHouse',
      name: 'myHouse',
      component: () => import('@/views/house/myHouse.vue'),
      meta: {
        title: '我的房屋信息'
      }
    },
    {
      path: '/my_house_list',
      name: 'my_house_list',
      component: () => import('@/views/house/my_house_list.vue'),
      meta: {
        title: '我发布的房屋列表'
      }
    },
    {
      path: '/my_like_house',
      name: 'my_like_house',
      component: () => import('@/views/house/my_like_house.vue'),
      meta: {
        title: '我关注的房屋列表'
      }
    },
    {
      path: '/house_list',
      name: 'house_list',
      component: () => import('@/views/house/house_list.vue'),
      meta: {
        title: '房屋列表'
      }
    },
    {
      path: '/house_details',
      name: 'house_details',
      component: () => import('@/views/house/house_details.vue'),
      meta: {
        title: '房屋详情'
      }
    },
    {
      path: '/deal_done',
      name: 'deal_done',
      component: () => import('@/views/house/deal_done.vue'),
      meta: {
        title: '房屋交易成功'
      }
    },
    {
      path: '/job_list',
      name: 'job_list',
      component: () => import('@/views/job/job_list/job_list.vue'),
      meta: {
        title: '招聘求职列表'
      }
    },
    {
      path: '/job_detail',
      name: 'job_detail',
      component: () => import('@/views/job/job_detail/job_detail.vue'),
      meta: {
        title: '招聘求职详情'
      }
    },
    {
      path: '/my_job',
      name: 'my_job',
      component: () => import('@/views/job/my_job/my_job.vue'),
      meta: {
        title: '我发布的招聘求职'
      }
    },
    {
      path: '/job_edit',
      name: 'job_edit',
      component: () => import('@/views/job/job_edit.vue'),
      meta: {
        title: '我发布的招聘求职'
      }
    },
    {
      path: '/my_like_job',
      name: 'my_like_job',
      component: () => import('@/views/job/my_job/my_like_job.vue'),
      meta: {
        title: '我关注的招聘求职'
      }
    },
    {
      path: '/jos_deal_done',
      name: 'jos_deal_done',
      component: () => import('@/views/job/deal_done.vue'),
      meta: {
        title: '招聘求职交易成功'
      }
    },
    {
      path: '/my_integral',
      name: 'my_integral',
      component: () => import('@/views/index/my_integral.vue'),
      meta: {
        title: '我的积分'
      }
    },
    {
      path: '/myBank',
      name: 'myBank',
      component: () => import('@/views/index/myBank.vue'),
      meta: {
        title: '我的银行信息'
      }
    },
    {
      path: '/image',
      name: 'image',
      component: () => import('@/views/index/image.vue'),
      meta: {
        title: '我的分享图'
      }
    },
    {
      path: '/marry',
      name: 'marry',
      component: () => import('@/views/index/marry.vue'),
      meta: {
        title: '找到对象了'
      }
    },
    {
      path: '/select_user',
      name: 'select_user',
      component: () => import('@/views/topup/select_user.vue'),
      meta: {
        title: '选择对象'
      }
    },

    // 404 页面路由
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/error-page/404/index.vue'),
      meta: {
        title: '页面走丢了'
      }
    }
  ]
)

const router = new VueRouter({
  routes,
  // 页面滚动行为
  scrollBehavior( /* to, from, savedPosition */ ) {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
