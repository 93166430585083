<template>
  <div class="userData">
    <div class="userData-nav">
      <van-nav-bar :title="headFixed? '个人主页' :''" :class="!headFixed ? 'noNav' :''" left-arrow
        @click-left="$router.go(-1)" />
    </div>
    <div class="userData-head">
      <van-swipe @change="onChange">
        <van-swipe-item v-for="(item,index) in banner" :key="index">
          <img :src="item.img" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">{{ current + 1 }}/{{banner.length}}</div> <!-- 幻灯 -->
        </template>
      </van-swipe>
    </div>

    <div class="userData-content">
      <div class="userData-content-user">
        <div class="userData-content-user-left">
          <span class="userData-content-user-title">{{user.name}}</span>
          <img :src="user.sex == '2' ? sexM : sexMan" class="userData-content-user-sex" alt="">
          <span class="userData-content-user-age">{{user.age}}</span>
          <!-- <span  style="font-size: 3.2vw;color: #4db0fd;margin-left: 10px;"  @click="report()"> 举报</span> -->
        </div>
        <div class="userData-content-user-right">
          <img :src="user.haslike == '1' ? likeIs : likeNot" alt="" @click="like()">
          <div>{{user.like}}</div>
        </div>
      </div>

      <div class="userData-tabs">
        <div class="userData-tabs-thead">
          <div v-for="(item,index) in tabs" :key="index" class="userData-tabs-thead-item"
            :class="index == tabCur ? 'cur' : ''" @click="tabCur = index">{{item.name}}</div>
        </div>
        <div class="userData-tabs-tbody">
          <div v-if="tabCur == 0" class="userData-tbody1">
            <div v-for="(item,index) in tabs[0].data" :key="index" class="userData-tbody1-item">
              <div class="userData-tbody1-item-box">
                <img :src="user.img" class="userData-tbody1-item-user" alt="">
                <div class="userData-tbody1-item-txtB">
                  <div class="userData-tbody1-item-name">{{user.name}}</div>
                  <div class="userData-tbody1-item-age">
                    <img :src="user.sex == 1 ? sexM : sexMan" class="userData-content-user-sex" alt="">
                    <span class="userData-content-user-age">{{user.age}}</span>
                  </div>
                  <!--       <img src="../../assets/img/msg1.png" class="userData-tbody1-item-msg
                      " alt=""> -->
                </div>
              </div>
              <div class="userData-tbody1-item-cont">{{item.content}}</div>
              <div class="userData-tbody1-item-time">{{item.date}}</div>
            </div>
          </div>

          <div v-else-if="tabCur == 1 " class="userData-tbody2">
            <div v-for="(item,index) in tabs[tabCur].data" :key="index" class="userData-tbody2-item">
              <img :src="item.img" alt="">
            </div>
            <!--   <div class="userData-tbody4-p">暂无视频</div> -->
          </div>
          <!--   <div v-else-if=" tabCur == 2" class="userData-tbody2">
            <div v-for="(item,index) in tabs[tabCur].data" :key="index" class="userData-tbody2-item">
              <img :src="item.img" alt="">
            </div>
            <div class="userData-tbody4-p">暂无视频</div>
          </div> -->
          <!-- 个人资料 -->
          <div v-else class="userData-tbody4">
            <div class="userData-tbody4-title">基本资料</div>
            <!--  <div class="userData-tbody4-p">性别: {{tabs[3].data.sex}}</div> -->
            <div class="userData-tbody4-p">出生地址: {{tabs[3].data.adder}}</div>
            <div class="userData-tbody4-p">工作地址: {{tabs[3].data.workplace}}</div>
            <div class="userData-tbody4-p">自我介绍: {{user.introduction}}</div>
          </div>
        </div>
      </div>
      <div class="m-report-qm" style="bottom: 62px; ">
        <a @click="report();" class="clearfix">
          <i></i>举报
        </a>
        <div class="txt">
          <h3>如遇假单身 骗婚 酒托 骚扰等 请举报</h3>
          <p>请小心谨慎，不要被甜言蜜语所迷惑，对借钱等情况一定要保持高度警觉，避免感情被骗，人财两空</p>
        </div>
      </div>
      <div class="userData-fixed">
        <div class="userData-fixed-left">
          <img class="userData-fixed-like" style="margin: -0.5vw 5.333vw;width:24px;height:24px;"
            :src="haslike =='1' ? likeIs : likeNot" alt="" @click="like()">
          <img class="userData-fixed-liwu" src="../../assets/img/gifti.png" alt="" @click="gift()"
            style="display:none;">
          <img class="userData-fixed-xiaoxi" src="../../assets/img/msg1.png" alt="" @click="message()">

        </div>

        <div class="userData-fixed-right" @click="email()" style="display:none;">邮箱</div>

        <!--        <img class="userData-fixed-right" src="../../assets/img/ico03.png" alt=""
          @click="report()"> -->
        <!-- 拉黑 -->
        <img class="userData-fixed-right" style="width:20px;height:20px;" src="../../assets/img/blacklist.jpg" alt=""
          @click="toBlack()">
      </div>
    </div>
  </div>
</template>

<script>
  const ting = require('../../assets/img/shaofu.jpg');
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Popup,
    Toast
  } from "vant";
  import wx from 'weixin-js-sdk';
  export default {
    name: 'userData',
    userCode: '', //本页会员的账号
    data() {
      return {
        banner: [
          // {
          //   img: require('../../assets/img/1.jpg')
          // },
          // {
          //   img: require('../../assets/img/1.jpg')
          // },
        ],
        haslike: '0', //0 不喜歡 1 喜歡
        current: 0,
        likeIs: require('../../assets/img/like.png'),
        likeNot: require('../../assets/img/ico01.png'),
        sexMan: require('../../assets/img/Man.png'),
        sexM: require('../../assets/img/woman.png'),

        user: {
          userCode: '',
          name: '',
          sex: '1',
          age: 0,
          like: 0,
          haslike: false,
          img: ting,
          adder: '',
          introduction: '' //自我介绍
        },
        tabCur: 0,
        tabs: {
          0: {
            name: '动态',
            data: [
              // {
              //   id: 1,
              //   content: 'tingting加入了我们的网站',
              //   date: '3月 17'
              // },
              // {
              //   id: 1,
              //   content: 'tingting加入了我们的网站',
              //   date: '3月 17'
              // },
              // {
              //   id: 1,
              //   content: 'tingting changed their avatar',
              //   date: '3月 17'
              // },
            ]
          },
          1: {
            name: '照片',
            data: [
              // {
              //   img: require('../../assets/img/shuaige.png')
              // },
              // {
              //   img: require('../../assets/img/shuaige.png')
              // },
              // {
              //   img: require('../../assets/img/shuaige.png')
              // },
              // {
              //   img: require('../../assets/img/shuaige.png')
              // },
              // {
              //   img: require('../../assets/img/shuaige.png')
              // },
            ]
          },
          // 2: {
          //   name: '视频',
          //   data: [

          //   ]
          // },
          3: {
            name: '个人资料',
            data: {
              sex: '',
              adder: ''
            }
          }

        },
        timer: null,
        headFixed: false
      }
    },
    mounted() {
      var url = location.href;
      window.addEventListener("scroll", this.handleScroll);
      this.userCode = decodeURI(getQueryVariable(url,
        "userCode"));
      console.log("================>" + this.userCode)
      //加载这个会员的详情
      this.getUserInfo(this.userCode);
      //加载这个会员的动态
      this.getAct(this.userCode);
      this.getPhotoList(this.userCode);
      this.getWxConfig(); //加载js
      //
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });

        } else {
          Toast(result.msg);
        }
      },
      onChange(index) {
        this.current = index;
      },
      handleScroll() {
        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          if (scrollTop > 100) {
            this.headFixed = true;
          } else {
            this.headFixed = false;
          }
        }, 50);
      },
      async getUserInfo(userCode) {
        //获取会员部分信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getLikeUserInfo2021",
          user: Cookies.get("kh"),
          queryUser: userCode, //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //that.firendJson = result.data;
          that.user.userCode = result.kh
          that.user.name = result.name
          that.user.sex = result.sex
          that.user.age = result.age
          that.user.like = result.likes
          that.user.haslike = result.haslike
          that.haslike = result.haslike
          that.user.img = result.avatar
          that.user.introduction = result.introduction

          that.tabs[3].data.adder = result.address
          that.tabs[3].data.workplace = result.workplace
          that.tabs[3].data.sex = result.sex

          //banner
          var sData = {
            img: result.avatar,
          };
          that.banner.push(sData);

        } else {
          Toast(result.msg);
        }
      },
      async getAct(userCode) {
        //获取动态，可以获取全部动态和个人的动态
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getAct2021",
          user: Cookies.get("kh"),
          queryUser: userCode, //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: '18777777777',
          type: '4', //1是全部的动态， 2是自己的动态 3是自己关注的会员的动态 4,指定会员动态，后台有权限判断
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //that.firendJson = result.data;
          var data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                id: 1,
                content: data[i].act,
                date: data[i].actDate,
              };
              that.tabs[0].data.push(sData);
            }
          }
        } else {

        }
      },
      async getPhotoList(userCode) {
        //获取指定会员的照片列表
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getPhotoList2021",
          user: Cookies.get("kh"),
          type: 2,
          queryUser: userCode,
          sitenum: '18777777777',
          token: Cookies.get("token"), //验证用户合法性
        };
        var result = await RequestApi.post(uri, param);

        if (result.status == "1") {
          var data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                img: data[i].photoUrl,
                title: data[i].title,
                id: data[i].id,
              };
              that.tabs[1].data.push(sData);
            }
          }

        } else {
          //没有任何照片

        }
      },
      async like() {
        //喜欢 关注
        //不传值，直接根据数据库是否关注反向切换
        let that = this;
        let uri = this.$global.domain + "/tools/MallSelect.ashx";
        let param = {
          action: "collection2021",
          user: Cookies.get("kh"),
          code: this.userCode, //user kh 都是会员的账号，有些接口之前用的是kh  喜歡誰
          type: '2', //2是收藏会员，1是收藏商品
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        let result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          if (that.haslike == "1") {
            that.haslike = "0"
            that.user.like = parseInt(that.user.like) - 1
          } else {
            that.haslike = "1"
            that.user.like = parseInt(that.user.like) + 1
          }
          that.user.haslike = that.haslike

        } else {

        }
      },
      message(item) {
        //消息中心
        this.$router.push({
          path: '/chat',
          query: {
            'userCode': this.userCode
          }
        });
      },
      report() {
        //改为调用企业微信客服
        window.location.href = "https://work.weixin.qq.com/kfid/kfc87b5402ee83a9aec";
        //联系客服 举报
        //this.$router.push("chat?userCode=service");
      },
      email(item) {
        //消息中心
        this.$router.push({
          path: '/reply',
          query: {
            'userCode': this.userCode
          }
        });
      },
      async toBlack(item) {
        //拉黑
        this.$confirm({
          message: "您确认要拉黑?",
          button: {
            no: '我再想想',
            yes: '是的'
          },
          callback: confirm => {
            if (confirm) {

              let that = this;
              let uri = this.$global.domain + "/tools/MallSelect.ashx";
              let param = {
                action: "collection2021",
                user: Cookies.get("kh"),
                code: this.userCode, //user kh 都是会员的账号，有些接口之前用的是kh  喜歡誰
                type: '44', //2是收藏会员，1是收藏商品  44就是拉黑 拉黑就是他不能给你发信息
                sitenum: '18777777777',
                token: Cookies.get("token"),
              };

              RequestApi.post(uri, param)
                .then(result => {
                  if (result.status == "1") {
                    //拉黑失败
                    console.log(result.msg);
                    Toast(result.msg);
                    //this.$router.back(); //直接后退，这样等于重新刷新了聊天列表

                  } else {
                    //拉黑失败
                    Toast(result.msg);
                  }
                });

            }
          }
        })


      },
      gift(item) {
        //给这个会员送礼物
        this.$router.push({
          path: '/gift',
          query: {
            'type': 'sendgifts', //送礼状态下，不显示会员自己收到的礼物
            'userCode': this.userCode
          }
        });
      },

    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }
</script>

<style lang="less" scoped>
  .userData {
    position: relative;
    padding-top: 300px;
    z-index: 1;
    // background: #ddd;
    background: #fff;
  }

  /deep/ .noNav {
    background: transparent;
    border-radius: none;
    border-bottom-width: 0;
  }

  /deep/ .noNav.van-hairline--bottom::after {
    border-bottom-width: 0;

  }

  .userData-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .userData-head {
    width: 100%;
    overflow: hidden;
    height: 320px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    /deep/ .van-swipe {
      height: 320px;
    }

    img {
      // min-width: 100%;
      height: 100%;
      width: 100%;
    }

    .custom-indicator {
      position: absolute;
      right: 15px;
      bottom: 35px;
      // padding: 2px 5px;
      width: 60px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 15px;
      color: #fff;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .userData-content {
    position: relative;
    border-radius: 20px 20px 0 0;
    background: #fff;
    padding: 0 15px;

    &-user {
      padding: 35px 20px;
      border-bottom: 1px solid #F8F8FA;
      display: flex;
      justify-content: space-between;

      &-left {
        font-size: 16px;
      }

      &-sex {
        margin: 0 10px 0 20px;
        width: 10px;
      }

      &-age {
        font-size: 12px;
      }

      &-right {
        text-align: center;
      }
    }

    .userData-tabs {
      &-thead {
        display: flex;
        color: #B5B5B5;
        margin-bottom: 25px;
        line-height: 40px;

        &-item {
          margin-right: 15px;
          font-size: 14px;
          padding-bottom: 6px;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 4px;
            height: 2px;
            background: transparent;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -2px;
          }

          &.cur {
            font-size: 18px;
            color: #B6676D;
          }

          &::after.cur {
            background: #CD5465;
          }
        }
      }
    }

    .userData-tbody4 {
      &-title {
        font-weight: bold;
        margin-bottom: 10px;
      }

      &-p {
        font-size: 12px;
        color: #949397;
        line-height: 1;
        margin-bottom: 6px;
      }
    }

    .userData-tbody1 {
      &-item {
        padding: 15px 0;
        border-bottom: 1px solid #EFEFEF;

        &-box {
          padding-left: 60px;
          position: relative;
          min-height: 55px;
        }

        &-user {
          position: absolute;
          left: 0;
          right: 0;
          width: 55px;
          height: 55px;
          border-radius: 50%;
        }

        &-msg {
          position: absolute;
          top: 30px;
          right: 10px;
          width: 22px;
        }

        &-cont {
          margin: 10px 0 20px;
        }

        &-time {
          color: #C0C0C0;
          font-size: 12px;
        }

        &-txtB {
          text-align: left;

          .userData-content-user-sex {
            margin-top: 6px;
            margin-left: 0;
          }
        }
      }
    }

    .userData-tbody2 {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      &-item {
        width: 48%;
        margin-top: 4px;
        margin-left: 2%;

        img {
          width: 100%;
        }
      }
    }

  }

  .userData-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    height: 60px;
    border-top: 1px solid #F1F1F1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    &-right {
      height: 6.4vw;
      margin-right: 15px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      // background: #FF4C54;
      // height: 40px;
      line-height: 40px;
      padding: 0 20px;
      border-radius: 20px;
    }

    &-left {
      margin-left: 15px;
    }

    &-like {
      height: 24px;
    }

    &-liwu {
      // width: 22px;
      margin: 0 20px;
      height: 24px;
    }

    &-xiaoxi {
      width: 20px;
      // height: 24px;
    }
  }

  .m-report-qm {
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 2.667vw;
    padding: 2.667vw;
    border-top: 1px #eee solid;
    border-bottom: 1px #eee solid;
  }

  .m-report-qm a {
    display: block;
    width: 17.067vw;
    float: right;
    border-left: 1px #f5f5f5 solid;
    margin-left: 2.667vw;
    text-align: center;
    font-size: 3.2vw;
    color: #4db0fd;
    line-height: 3.733vw;
  }

  .m-report-qm .txt {
    overflow: hidden;
    font-size: 3.2vw;
    color: #999;
    line-height: 3.733vw;
  }

  .m-report-qm .txt h3 {
    font-size: 4vw;
    color: #fb9031;
    font-weight: 400;
    line-height: 4.8vw;
    margin-bottom: 2.4vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .m-report-qm a i {
    display: block;
    width: 8vw;
    height: 8vw;
    background-position: -88vw -15.6vw;
    margin: 0 auto;
  }
</style>
